import { createContext, useContext, useState } from 'react'
import { type Company, HideProfile, type Companies } from '../ext/HideProfiler'
import { useAppContext } from './AppContext'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

interface CurrentUserContextType {
  profile: HideProfile
  profileLoading: boolean
  profileRefetching: boolean
  refetchProfile: () => void
  token?: string
  setToken: any
  authenticated: boolean
  setAuthenticated: any
  permissions: ProfilePermissions
  idTokenUser: HideProfile
  setIdTokenUser: (profile: HideProfile) => void
  profileLoadingError: boolean
}

const PROFILE_ROLES = {
  participant: 'Participant',
  supervisor: 'Management',
  instructor: 'Instructor'
}

export interface ProfilePermissions {
  isParticipant: boolean
  isDataSharingSupervisor: boolean
  isInstructor: boolean
  isProvisioningSupervisor: boolean
  isStronglyAuthenticated: boolean
}

const companyIsOfType = (company: Company, type: string) => company?.metadata?.portal_type === type
const companiesOfType = (companies: Companies, type: string) => companies.filter((company) => companyIsOfType(company, type))
const hasSupervisorRoles = (companies: Companies) => companies.some(company => (company?.roles ?? []).some(roles => roles.name === PROFILE_ROLES.supervisor))

const getPermissions = (profile: HideProfile): ProfilePermissions => {
  const isStronglyAuthenticated = profile.isStronglyAuthenticated

  // User is a participant if they are strongly authenticated AND have a role Participant
  const isParticipant = profile.roles.includes(PROFILE_ROLES.participant)

  // TODO: does an instructor need strong authentication?
  const isInstructor = profile.roles.includes(PROFILE_ROLES.instructor)

  // User is an LDSA supervisor if they are strongly authenticated AND have at least one company with role Management
  const ldsaCompanies = companiesOfType(profile.companies, 'ldsa')
  const isDataSharingSupervisor = isStronglyAuthenticated && hasSupervisorRoles(ldsaCompanies)

  // User is a PA supervisor if they are strongly authenticated AND have at least one company with role Management
  const paCompanies = companiesOfType(profile.companies, 'pa')
  const isProvisioningSupervisor = isStronglyAuthenticated && hasSupervisorRoles(paCompanies)

  return {
    isStronglyAuthenticated,
    isParticipant,
    isInstructor,
    isDataSharingSupervisor,
    isProvisioningSupervisor
  }
}

const CurrentUserContext = createContext<CurrentUserContextType | null >(null)

export const CurrentUserProvider = ({ children }: { children: any }) => {
  const [profile, setProfile] = useState<HideProfile>(new HideProfile())
  const [token, setToken] = useState<string>()
  const [authenticated, setAuthenticated] = useState(false)
  const [idTokenUser, setIdTokenUser] = useState<HideProfile>(new HideProfile())
  const permissions = getPermissions(profile)
  const { appLoaded, api } = useAppContext()
  const { i18n } = useTranslation()
  const [ready, setReady] = useState(false)
  const [profileLoadingError, setProfileLoadingError] = useState(false)

  const { refetch: refetchProfile, isLoading, isRefetching } = useQuery({
    // Load user data once app has loaded
    enabled: appLoaded,
    // Make sure we load user data only once
    staleTime: Infinity,
    cacheTime: Infinity,
    queryKey: ['user', 'current'],
    queryFn: async () => {
      const isAuthenticated = await api.isAuthenticated()
      if (isAuthenticated) {
        try {
          let accessToken
          try {
            accessToken = token ?? await api.getCurrentToken()
          } catch (tokenError) {
            await api.logDiagnostics('Token retrieval error:', 'ERROR', tokenError)

            // Silent logout if token is invalid
            if (tokenError instanceof Error && tokenError.message?.includes('Login required')) {
              await api.logDiagnostics('Session expired, performing silent logout', 'TRACE')

              // Check URL that is important to be redirected to, if not, redirect to the home
              const pagesToReturnOnLogout = ['course-invitation', 'ldsa-consent', 'custom-signup']

              if (pagesToReturnOnLogout.includes(window.location.pathname.split('/')[1])) {
                await api.logout({
                  logoutParams: {
                    returnTo: window.location.href
                  }
                })
              } else {
                await api.logout()
              }

              return { isAuthenticated: false }
            }

            throw tokenError
          }

          let newIdTokenUser
          try {
            newIdTokenUser = idTokenUser?.userId ? idTokenUser : await api.getIdTokenUser()
          } catch (idTokenError) {
            await api.logDiagnostics('ID token user retrieval error:', 'ERROR', idTokenError)
            throw idTokenError
          }

          let userResponse
          try {
            userResponse = await api.getCurrentUser()
          } catch (userError) {
            await api.logDiagnostics('User data retrieval error:', 'ERROR', userError)
            throw userError
          }

          const user = userResponse?.data
          return { newIdTokenUser, user, isAuthenticated, accessToken }
        } catch (e) {
          await api.logDiagnostics('Loading user failed:', 'ERROR', e)
          return await Promise.reject(new Error('Loading user failed'))
        }
      }
      return {}
    },
    onSuccess: async (data) => {
      if (data.isAuthenticated) {
        setAuthenticated(!!data.isAuthenticated)
      }
      if (data.user) {
        setProfile(data.user)
        await i18n.changeLanguage(
          data.user.lang ?? 'en'
        )
      }
      if (data.newIdTokenUser) {
        setIdTokenUser(data.newIdTokenUser)
      }
      if (data.accessToken && typeof data.accessToken === 'string') {
        setToken(data.accessToken)
      }
      setReady(true)
      setProfileLoadingError(false)
    },
    onError: (error: Error) => {
      if (error.message === 'Loading user failed') {
        setAuthenticated(true)
        setToken(undefined)
        setProfile(new HideProfile())
        setProfileLoadingError(true)
      } else if (!profile.userId) {
        setAuthenticated(false)
        setToken(undefined)
        setProfile(new HideProfile())
      }
      setReady(true)
    }
  })

  return (
    <CurrentUserContext.Provider value={{
      profile,
      profileLoading: !ready || isLoading,
      profileRefetching: isRefetching,
      refetchProfile,
      token,
      setToken,
      authenticated,
      setAuthenticated,
      permissions,
      idTokenUser,
      setIdTokenUser,
      profileLoadingError
    }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUserContext = () => useContext(CurrentUserContext) as CurrentUserContextType
