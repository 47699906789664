import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoading from 'components/LazyLoading'
import { useAppContext } from 'contexts/AppContext'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Routes } from 'routes/Routes'

function App () {
  const { t, i18n } = useTranslation()

  const handleLanguageChanged = useCallback(() => {
    document.title = t('app.pageTitle')
  }, [t])

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged)
    return () => {
      i18n.off('languageChanged', handleLanguageChanged)
    }
  }, [handleLanguageChanged, i18n])

  useMissingTranslationLogging()

  const router = createBrowserRouter(Routes)

  return (
    <RouterProvider router={router} fallbackElement={<LazyLoading />} />
  )
}

const useMissingTranslationLogging = () => {
  const { i18n } = useTranslation()
  const { api, appLoaded } = useAppContext()

  const onMissingKey = useCallback(async (lngs: string, _namespace: any, key: string, _res: any) => {
    try {
      // We do not report missing NL language strings yet, as it is added as "empty" language for now, to debug translations
      // TODO: Remove this when NL is re-introduced as Real langauge, or taken away.
      if (lngs !== 'nl' && appLoaded) {
        await api.logDiagnostics('Missing translation', 'WARN', { key, language: lngs })
      }
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message)
      } else {
        console.error(err)
      }
    }
  }, [api, appLoaded])

  useEffect(() => {
    i18n.on('missingKey', onMissingKey)

    return () => {
      i18n.off('missingKey', onMissingKey)
    }
  }, [i18n, onMissingKey])
}

export default App
